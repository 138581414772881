const colors = require("./colors.json")

function classNameGenerator(colors, prefix) {
  return Object.entries(colors).flatMap(([color, shades]) => {
    return Object.keys(shades).map(weight => `${prefix}-${color}-${weight}`);
  });
}

const bgClasses = classNameGenerator(colors, "bg");
const textClasses = classNameGenerator(colors, "text");

module.exports = {
  content: [
    "./app/**/*.{rb,erb}",
    "./app/assets/stylesheets/**/*.css",
    "./app/javascript/**/*.{js,ts}",
  ],
  safelist: [
    ...bgClasses,
    ...textClasses,
    "border-l-green-600",
    "border-l-green-400",
    "border-l-pink-400",
    "border-l-gray-400",
    "border-l-blue-900",
  ],
  theme: {
    borderColor: (theme) => ({
      ...theme("colors"),
      DEFAULT: theme("colors.gray.300", "currentColor")
    }),
    /*
     * The colors are defined in colors.json
     * This is a translation of the old color weights to the new color weights
     *    *-blue       = *-blue-900
     *    *-orange     = *-orange-500
     *    *-gray-100   = *-gray-50
     *    *-gray-200   = *-gray-100
     *    *-gray-300   = *-gray-200
     *    *-gray-400   = *-gray-300
     *    *-gray-500   = *-gray-400
     *    *-gray-600   = *-gray-950
     *    *-green-50   = *-green-50
     *    *-green-100  = *-green_400
     *    *-green-200  = *-green-600
     *    *-pink       = *-pink-400
     *    *-red        = *-red-800
    */
    colors: {
      current: "currentColor",
      transparent: "transparent",
      black: "#000000",
      white: "#FFFFFF",
      blue: colors.blue,
      orange: colors.orange,
      gray: colors.gray,
      green: colors.green,
      pink: colors.pink,
      red: colors.red,
      purple: colors.purple,
      yellow: colors.yellow,
      success: "#4CC62F",
      danger: "#C72930",
    },
    container: { center: true, padding: { DEFAULT: "2rem" } },
    fontSize: {
      xs: "0.75rem",
      sm: "0.875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "2xl": "1.375rem",
      "3xl": "1.5rem",
      "4xl": "1.75rem",
      "5xl": "2rem",
      "6xl": "2.5rem",
      12: "3rem"
    },
    extend: {
      boxShadow: {
        DEFAULT: "0px 0px 20px rgba(0, 0, 0, 0.08)",
        danger:
          "inset 0px 0px 0px 1px rgba(199, 41, 48, 0.5), inset 0px 1px 1px rgba(199, 41, 48, 0.6)",
        input:
          "inset 0px 0px 0px 1px rgba(16, 22, 26, 0.15), inset 0px 1px 1px rgba(16, 22, 26, 0.2)"
      },
      fontFamily: {
        cabin: "Cabin",
        sans: ['"Cabin"']
      },
      lineHeight: {
        4.5: "1.125rem",
        13: "3rem",
        14: "3.5rem"
      },
      maxHeight: { "1/4": "25%", "1/2": "50%", "3/4": "75%" },
      maxWidth: { "1/4": "25%", "1/2": "50%", "3/4": "75%" },
      minHeight: { 60: "15rem" },
      opacity: { 65: "0.65" },
      screens: {
        print: { raw: "print" }
      },
      spacing: {
        13: "3.25rem",
        18: "4.5rem",
        "1/12": "8.333333%",
        "2/12": "16.666667%",
        "3/12": "25%",
        "4/12": "33.333333%",
        "5/12": "41.666667%",
        "6/12": "50%",
        "7/12": "58.333333%",
        "8/12": "66.666667%",
        "9/12": "75%",
        "10/12": "83.333333%",
        "11/12": "91.666667%"
      }
    }
  },
  plugins: [
    require("@tailwindcss/aspect-ratio"),
    require("@tailwindcss/forms"),
    require("@tailwindcss/typography")
  ]
}
