import { Controller } from '@hotwired/stimulus';

const ACTIVE_CLASSES = ['z-20', 'opacity-100'];
const INACTIVE_CLASSES = ['z-0', 'opacity-0'];
export default class extends Controller {
  static targets = ["slide", "indicator"];

  static values = { activeSlide: Number, indicatorCount: Number, autoScroll: Boolean };

  declare readonly slideTargets: HTMLElement[];

  declare readonly indicatorTargets: HTMLElement[];

  declare activeSlideValue: number;

  declare autoScrollValue: boolean;

  connect() {
    if (!!this.autoScrollValue) this.autoScroll();
  }

  previous() {
    this.activeSlideValue = this.wrapIndex(this.activeSlideValue - 1);
  }

  next() {
    this.activeSlideValue = this.wrapIndex(this.activeSlideValue + 1);
  }

  wrapIndex(index: number) {
    const length = this.slideTargets.length;
    return Math.abs(index - length * Math.floor(index / length));
  }

  autoScroll() {
    setInterval(() => this.next(), 5000);
  }

  activeSlideValueChanged() {
    this.setIndicator();
    this.resetSlides();
    this.setActiveSlide();
  }

  setIndicator() {
    this.indicatorTargets.forEach((indicator: HTMLElement) => this.setClasses(indicator, ['far'], ['fas']));
    this.setClasses(this.indicatorTargets[this.activeSlideValue], ['fas'], ['far']);
  }

  resetSlides() {
    this.slideTargets.forEach((slide: HTMLElement) => {
      slide.querySelectorAll("a").forEach((button_link: HTMLElement) => this.setTabIndex(button_link, -1));
      this.setClasses(slide, INACTIVE_CLASSES, ACTIVE_CLASSES);
    });
  }

  setActiveSlide() {
    const activeSlide = this.slideTargets[this.activeSlideValue];
    activeSlide.querySelectorAll("a").forEach((button_link: HTMLElement) => this.setTabIndex(button_link, 0));
    this.setClasses(activeSlide, ACTIVE_CLASSES, INACTIVE_CLASSES);
  }

  setTabIndex(item: HTMLElement, index: number) {
    item.tabIndex = index;
  }

  setClasses(element: HTMLElement, addList: string[], removeList: string[]) {
    element.classList.add(...addList);
    element.classList.remove(...removeList);
  }
}
